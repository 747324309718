import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class FooterService {
  private footerConfigSub = new Subject<any>();
  private footerMetaConfigSub = new BehaviorSubject<any>(null);
  private footerMetaQnAConfigSub = new BehaviorSubject<any>(null);

  constructor() { }
  
  public setFooterConfig(conf: string, val: any){
    this.footerConfigSub.next({'config' : conf, 'value': val});
  }
  public getFooterConfig(){
    return this.footerConfigSub.asObservable();
  }

  public setMetadataConfig(meta: any){
    this.footerMetaConfigSub.next(meta);
  }
  public getMetadataConfig(){
    return this.footerMetaConfigSub.asObservable();
  }
  public setMetaQnADataConfig(meta: any){
    this.footerMetaQnAConfigSub.next(meta);
  }
  public getMetaQnADataConfig(){
    return this.footerMetaQnAConfigSub.asObservable();
  }
}